/* Header.css */
body {
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column; 
  position:absolute;
  top: 0;
  width: 100%;
  z-index: 10; 
  padding: 100px;
  height: 180px;


}
.header:hover{
  background-color: rgba(0.1, 0.4, 0.2, 0.9);
}

.logo-container {
  display: flex; /* Use flexbox for the logo and lines */
  align-items: center;
  width: 100%; /* Full width */
  justify-content: center; /* Center the content */
  margin-bottom: 20px; /* Increased space between logo and nav */
  position: absolute; 
  left: 50%; 
  transform: translateX(-50%); 
}

.line {
  height: 1px; /* Line height */
  border-bottom: 2px solid white; 
  flex-grow: 1; /* Allow the lines to grow */
  margin: 0px 90px; /* Margin for spacing between line and logo */

 
}
.line-bn {
  height: 1px; /* Line height */
  background-color: white; /* Line color */
  flex-grow: 1; /* Allow the lines to grow */
  margin: 0; /* Margin for spacing between line and logo */

 
}

.logo {
  width: 150px; /* Adjust the logo size */
  height: auto;
  margin: 0 20px; /* Space from the lines */
}

.nav {
  display: flex;
  justify-content: space-between; /* Space between left and right menus */
  width: 100%; /* Full width */
}
.nav:hover{
  /* background-color: rgba(0.1, 0.4, 0.2, 0.4); */
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
 
  z-index: 1;
}

.nav-links.left {
  padding-top: 10px;
  margin-right: auto; 
  margin-left: 5%;
}

.nav-links.right {
  padding-top: 10px;
  margin-left: auto; 
  margin-right: 5%;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  /* color: white; */
  text-decoration: none;
  transition: color 0.3s;
}

.nav-links a:hover {

}
.menu-item{
  transition: color 0.3s; /* Smooth transition for color change */
  font-family: 'Montserrat', sans-serif; 
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  padding: 0px 1rem; /* Add padding to increase clickable area */
  display: inline-block;
  
}


@media (min-width: 768px) {
    .header{
    background-color: rgba(0.1, 0.1, 0.1, 0.1); 
  }
  .headerColor{
    background-color: black;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .headerColor{
    /* background-color: rgba(0.1, 0.1, 0.1, 0.1);  */
  }
  .header:hover{
    background-color: rgba(0.1, 0.1, 0.1, 0.1); 
  }
  .header{
    /* background-color: rgba(0.1, 0.1, 0.1, 0.1);  */
   
  }
  
  .nav {
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on header height */
    right: 0;
    background-color: #0a6e4d; /* Match header color */
    width: 100%;
    transition: max-height 0.3s ease;
    max-height: 0; /* Initially hide the menu */
    overflow: hidden; /* Prevent overflow */
  }

  .nav.open {
    max-height: 300px; /* Adjust based on the number of links */
  }

 

  .nav-links {
    flex-direction: column;
    align-items: center;
    padding-inline: 1.5rem;
  }

  .nav-links li {
    margin: 10px 0;
  }
}





/* Basic Navbar Styling */
.navbar {
  background-color: rgba(0, 0.0, 0.0, 0.0); 
 
  position: relative;
}

.navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.navbar-item {
  position: relative;
}

.navbar-item a {
  text-decoration: none;

  /* padding: 5px 10px; */
  display: inline-block;
  /* font-weight: bold; */
}
.sub_menu{
  padding-top: 56px;
  /* width: 100vw; */
}
/* Full-Width Submenu Styling */
.submenu {
  font-family: 'Montserrat', sans-serif; 
  list-style: none;
  left: -360px;
  /* margin: 0 !important; */
  /* padding: 0; */
  display: none; 
  position: absolute;

  /* left:0;
 right: 0; */
  top: 100%; 
  /* left: 0;  */
  background-color: rgba(0.1, 0.4, 0.2, 0.9);
  width: 100vw;
  z-index: 1;
  flex-direction: row;
  transition: left 0.3s ease;
   /* justify-content: flex-start;  */
}
.submenu li a{
  font-family: 'Montserrat', sans-serif; 
  font-size: 22px;
  padding-left: 50px;
}

/* Show submenu on hover */
.navbar-item:hover .submenu {
  display: flex; /* Display submenu on hover */
}

/* Submenu items with step-by-step animation */
.submenu li {
  padding: 10px;
  opacity: 0;
  transform: translateY(20px); /* Initially shift down a bit */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Step-by-step reveal with delays for each submenu item */
.submenu li:nth-child(1) {
  transition-delay: 0.1s;
}
.submenu li:nth-child(2) {
  transition-delay: 0.2s;
}
.submenu li:nth-child(3) {
  transition-delay: 0.3s;
}

/* Make submenu items visible when hovering */
.navbar-item:hover .submenu li {
  opacity: 1;
  transform: translateY(0); /* Move back to the original position */
}

/* White line separator between menu and submenu */
.navbar-item:hover {
  border-bottom: 1px solid #fff;
}

/* Responsive Menu for smaller screens */
@media (max-width: 768px) {
  .navbar-list {
    flex-direction: column;
  }

  .submenu {
    width: 100vw;
  }
}


.dropdown {
  position: absolute; /* Position the dropdown */
  top: 100%; /* Align it below the menu item */
  left: 0;
  background-color: #444; /* Dropdown background color */
  padding: 0.5rem;
  border-radius: 4px; /* Optional: Round the corners */
  display: flex;
  flex-direction: column; /* Stack dropdown items vertically */
  z-index: 1000; /* Ensure dropdown is on top */
 
}

.dropdown li {
  color: white;
  padding: 0.5rem 1rem; /* Padding for dropdown items */
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #555; /* Change background on hover */
}
.dropdown-icon {
  margin-left: 3px; /* Space between the menu text and icon */
  /* padding-top: 10px; */
  font-size: 1.5rem;
  display: inline;
}

.menu-toggle {
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: none;
  padding-bottom: 2%;
  background-color: black;
 

}

/* Drawer styles */
.drawer {
  position: fixed;
  top: 0;
  right: -250px;  /* Hide drawer initially */
  width: 250px;
  height: 100%;
  background-color: #333;
  padding-top: 60px;
  transition: right 0.3s ease;
}

.drawer.open {
  right: 0; /* Show drawer when open */
}

.drawer ul {
  list-style: none;
  padding: 0;
}

.drawer li {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid gray;
  font-size: large;
  cursor: pointer;
  transition: background-color 0.2s ease,
}

.drawer a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  

}

.drawer a:hover {
  background-color: #555;
}
@media (max-width: 768px) {
  .nav-menu {
    display: none; 
  }

  .menu-toggle {
    display: block;
   
 
  }
  .toggle{
    margin-left: -40%;
    margin-top: -80px;
  
   
 
  }

}
/* menu-toggle{
  display: none;
} */
@media(min-width:768px){
  .header :hover{
    /* background-color: black; */
  }

}








