
  @media (max-width: 1570px) {
    .project-list {
      display: none;
    }
  }
  @media (max-width: 760px) {
  .project_button {
    left: 25%; /* Center it horizontally */

  }
}
@media (min-width: 760px) {
  .project_button {
    left: 10%; /* Center it horizontally */
 
  }
}
.project_button {
  padding-top: 10%;
   position: relative;
   padding-bottom: 10%; /* Position it 10% from the bottom */
   transform: translateX(-50%); /* Centering trick */
   /* background-color: #3cc7f3; Button background color */
   color: white; /* Button text color */
   border:solid;
   padding: 10px 20px;
   font-size: 16px;
   border-radius: 5px;
   /* border-color: #3cc7f3; */
   cursor: pointer;
   transition: background-color 0.3s ease;
   border-radius: 15%;
   background-color: rgba(0.1, 0.4, 0.2, 0.4);
 }