
.service-list-container {
    /* padding: 4rem; */
    padding-top:15% ;
    width: 90%;
    margin-bottom: 2%;
    margin-left: 5%;
  }
  .service-page-container {
    /* padding: 4rem; */
    padding-top:5% ;
    /* width: 90%; */
    margin-left: 5%;
  }
  
  .service-card {
    transition: transform 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-10px); 
  }

  /* You can add this to a separate CSS file or use inline Tailwind classes */
.interior-design-list-container {
    padding-top:6%;
    width: 100%;
    /* margin-left: 5%; */
    padding-bottom: 2%;
    
    
    /* padding: 4rem; */
   
}
  
  .project-card {
    transition: transform 0.3s ease;
  }
  
  .project-card:hover {
    transform: translateY(-10px); /* Slight lift on hover */
  }
  .furniture-showcase-container{
   
    padding-top:15% ;
    width: 90%;
    margin-left: 5%;
    padding-bottom: 2%;
  
  }

  /* Add some CSS for interactive effects */
.furniture-card {
    transition: transform 0.3s ease;
    position: relative;
  }
  
  .furniture-card:hover {
    transform: translateY(-10px);
  }
  
  .overlay {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }
  
  .furniture-card:hover .overlay {
    opacity: 1;
  }

  .news-page-container{
    padding-top:15% ;
    width: 80%;
    margin-left: 10%;
    padding-bottom: 5%;
  }

  .news-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .news-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  #about-container{
    padding-top:15%;
    width: 90%;
    margin-left: 5%;
  }

/* .compare-container {
  height: 300px !important;
  overflow: hidden;
}

.construction-card img {
  position:  relative !important;
  height: 260px;
  object-fit: cover; 
}
*/

.construction-card{
  padding-top: 15%;
  margin-left: 5%;

 } 
.construction-card  img {
  display: none;
  height: 500px !important; 

  object-fit: cover;
}
.construction-card  div {

  height: 500px !important; 
 

}

.section {
  border-bottom: 0px solid #ddd ;
}

.section h2 {
  transition: color 0.3s;
}

.section h2:hover {
  color: #007bff;
}

.content {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cta button {
  transition: background-color 0.3s;
}

  
  
  
  
  