.interactive-logo {
    width: 90px;
    height: auto;
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 2s ease, transform 2s ease;
    filter: brightness(0) invert(1);
    justify-content: center; /* Center horizontally */
    align-items: center; 
}

.interactive-logo.visible {
    opacity: 1;
    transform: scale(1);
}
/* .logo-container {
    position: absolute; 
    left: 50%; 
    transform: translateX(-50%); 
  } */



