@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
html {
  scroll-behavior: smooth;
}
.perspective {
  perspective: 1000px;
}

.transform-style-3d {
  transform-style: preserve-3d;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.backface-hidden {
  backface-visibility: hidden;
}
.compare-image-container {
  height: 400px !important;
  width: 100%;
}

.video-background-container {
  position: relative;
  width: 100%;
  height: 70%; 

}

.video-background {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 70% !important;
  object-fit: cover;
  z-index: -1; 

}

.content-overlay {

}
/* Style for the video container */
.video-container {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust the height */
  overflow: hidden;
 
}

.home-page-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
  position: absolute;
  top: 0;
  left: 0;
}


/* Style for the video to cover the full viewport */
.video-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full screen height */
  overflow: hidden;
}

#homeVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Make sure the video covers the area without distortion */
  transform: translate(-50%, -50%);
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1));
}

.content {
  padding: 20px;
  margin-top: 100vh; /* Push the content below the video */
  background-color: white;
}
.img {
  position: relative;
  /* top: 50%; */
  /* left: 50%; */
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Make sure the video covers the area without distortion */
  transform: translate(-50%, -50%);
}
.image-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full screen height */
  overflow: hidden;
  background-color: darkgrey;
}
.image-container2 {
  position: relative;
  width: 100%;
  height: 100vh; /* Full screen height */
  overflow: hidden;
  /* background-color: darkgrey; */
}
#fullImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Make sure the image covers the area without distortion */
  transform: translate(-50%, -50%);
  filter: grayscale(300%);
}
#fullImage2 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  /* Make sure the image covers the area without distortion */
  transform: translate(-50%, -50%);
  filter: grayscale(80%);
}

/* Content that appears after the video and image */
.content {
  padding: 20px;
  margin-top: 0; /* No margin since it's below the image */
  background-color: white;
}
/* Hidden text initially */
.dynamic-text {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  color:white;
  font-size: 3em;
   font-family: 'Montserrat', sans-serif; 
  text-align: center;
  font-weight: bold;
  /* Optional dark background behind text */
  padding: 20px;
  border-radius: 10px;
  opacity: 0; /* Hide the text initially */
  transition: opacity 1s ease; /* Smooth transition for showing the text */
}
.textStyle {
  position: absolute;
  /* top: 30%;
  left: 10%; */
  transform: 'translate(-50%, -50%)';
  color: 'white';
  text-align: center;
 
  text-shadow: '2px 2px 4px rgba(0, 0, 0, 0.7)';
  white-space: nowrap;
  overflow: hidden;


};
#tit_display{
  /* font-family: 'Dancing Script', cursive;  */
 
  /* font-size: 26px;  */
  
}

.textStyle1 {
  position: absolute;
  top: 30%;
  left: 10%;
  transform: 'translate(-50%, -50%)';
  color: 'white';
  font-size: 3em;
  text-align: center;
  font-weight: bold;
  text-shadow: '2px 2px 4px rgba(0, 0, 0, 0.7)';
  font-family: 'Montserrat', sans-serif; 
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange; /* Blinking cursor effect */
  animation: blink 0.7s step-end infinite;
  font-weight: 200;
  font-size:80px;
};
.textStylewood {
  position: absolute;
  top: 30%;
  left: 30%;
  transform: 'translate(-50%, -50%)';
  color: 'white';
  font-size: 3em;
  
  text-align: center;
  font-weight: bold;
  text-shadow: '2px 2px 4px rgba(0, 0, 0, 0.7)';
  font-family: 'Montserrat', sans-serif; 
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange; /* Blinking cursor effect */
  animation: blink 0.7s step-end infinite;
  font-weight: 200;
  font-size:80px;
};

.slider-new{
  position: relative;
  top: 30%;
  left: 10%;
  
    transition-duration: 0ms;
    /* z-index: 5; */
    transform: translate3d(calc(-1% + 0px), 0px, -200px) rotateZ(0deg) scale(1);
   
}
.swiper-container {
  position: absolute;
  top: 40%;
  right: 0;
  width: 27%;
  height: 400px !important;
  padding-right: 10%;
  /* z-index: 1000; */
  /* margin: 20px;*/
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); */
  transition-duration:0ms;
  border-radius: 100px;

}

.swiper.swiper-cards {
  height:450px !important;
  box-shadow: 20px 20px 20px 30px rgba(0, 0, 0.2, 0.2); /* Adjust the shadow parameters */
 

}
.black-and-white-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%); /* This makes the image black and white */
  transition: filter 0.5s ease; /* Transition for hover effect */
}

/* Button Style */
.button {
  position: absolute;
  bottom: 10%; /* Position it 10% from the bottom */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Centering trick */
  /* background-color: #3cc7f3; Button background color */
  color: white; /* Button text color */
  border:solid;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  /* border-color: #3cc7f3; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 15%;
  background-color: rgba(0.1, 0.4, 0.2, 0.4);
}

.button:hover {
  background-color: black; 
}

/* Change the default color of the next and previous arrows */
.swiper-button-next,
.swiper-button-prev {
    color: white;
    font-size: 14px;
     /* Change this to your desired color */
}

/* Optionally, you can also customize the background or border of the arrows */
.swiper-button-next::after,
.swiper-button-prev::after {
    color: white; /* Customize the arrow color */
    transition: color 0.3s ease;
 
  /* Adjust the arrow size */   
}

/* Add a hover effect to make it more interactive */
.swiper-button-next:hover,
.swiper-button-prev:hover {
    color: white; /* Change arrow color on hover */
}
.swiper-pagination-bullet {
  background-color: gray !important; /* Default bullet color */
  opacity: 1 !important; /* Ensures the bullet is fully visible */
}

.swiper-pagination-bullet-active {
  background-color: white !important; /* Active bullet color */
}

.displayed_text{
  font-family: 'Montserrat', sans-serif; 
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange; /* Blinking cursor effect */
  animation: blink 0.7s step-end infinite;
  font-weight: 200;
  font-size:80px;
}

@keyframes blink {
  from, to {
      border-color: transparent;
  }
  50% {
      border-color: darkgrey;
  }
}
.hoverText {
  font-size: 1.5rem;
  color: white;
}




.background-image_interior {

 
}

.background-image_wood {
  background: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));
  background-size:contain;
  background-position:center;
  height: 100vh; /* Full viewport height */

}
.background-image_finishing {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9)),;
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full viewport height */
  /* filter: grayscale(300%); */
}

#title{
  text-decoration: underline;
  /* font-family: 'Cormorant Garamond', serif;
  font-size:80px; */
}
#titlewood{
  text-decoration: underline;
  left: 20%;
  /* font-family: 'Cormorant Garamond', serif;
  font-size:80px; */
}



.slider-new1{
  position: absolute;
  top: 40%;
  right: 0;
  width: 27%;
  height: 400px !important;
  padding-right: 10%;
  /* z-index: 1000; */
  /* margin: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);  */
  transition-duration:0ms
}


/* Container to position the slider */
/*  */

/* Container to position the slider */
.image-slider {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 90%;
  /* background-color: rgba(0.1, 0.4, 0.2, 0.4); */
 
  z-index: 100;
  
}

/* Swiper container */
.mySwiper {
  width: 100%;
  
 

}

/* Container for each slide content */
.slide-content {
  position: relative;
  overflow: hidden;
  border: solid lightcyan;
  border-radius: 20px;
  /* margin-bottom: 20px; */
 
  
  
  
}
.slide-content-construction {
  position: relative;
  overflow: hidden;
  border: solid lightcyan;
  border-radius: 20px;
  /* margin-bottom: 20px; */
  height: 250px;
  
  
  
}

/* Image styling */
.slide-content img {
  width: 100%;
  height: 100%;
  object-fit:cover;
  /* transition: height 0.3s ease, filter 0.3s ease; */
  border-radius: 20px;
  height: 250px; 
  
  /* filter: brightness(0.9); */
}

/* Hover effect for images */
.slide-content img:hover {
  transform: scale(1.3);
  filter: brightness(0.7);
  border-radius: 40px;
  /* height: 280px;  */

}

/* Hover content (text and button) */
.hover-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  color: white;
}

.hover-content h3 {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif; 
}

/* Button inside hover content */
.hover-button {

  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border: solid white;
  transition: background-color 0.3s ease;
  position: absolute;
  left: 50%; 
  transform: translateX(-50%); 
  cursor: pointer;
  background-color: rgba(0.1, 0.4, 0.2, 0.4);
  font-family: 'Montserrat', sans-serif; 
  

  
}

/* Button hover effect */
.hover-button:hover {
  background-color: black;
}

/* Show the hover content when the image is hovered */
.slide-content:hover .hover-content {
  opacity: 1;
  visibility: visible;
}
/* Base styles for all corner layers */
/* Gradient Overlay */
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0)); /* Adjust colors and direction */
  z-index: 1; /* Place behind the corners but above the video */
}

/* Corner Styles */
.corner {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 50px;  
  height: 50px; 
  z-index: 2; 
}

/* Top-left corner */
.top-left {
  top: 0;
  left: 0;
}

/* Top-right corner */
.top-right {
  top: 0;
  right: 0;
}

/* Bottom-left corner */
.bottom-left {
  bottom: 0;
  left: 0;
}

/* Bottom-right corner */
.bottom-right {
  bottom: 0;
  right: 0;
}
/* Linear Gradient Overlay */
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5)); 
  z-index: 1; 
}

/* Header.css */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

h2 {
  opacity: 0;
  transform: translateY(30px);
  animation: slideIn 0.8s ease forwards; /* 0.8s animation */
}


h1 {
  font-family: 'Montserrat', sans-serif; 
  font-size: x-large;
  opacity: 0;
  transform: translateY(30px);
  animation: slideIn 0.8s ease forwards; /* 0.8s animation */
}
p {
  font-family: 'Montserrat', sans-serif;   
}

h2 {
  font-family: 'Montserrat', sans-serif; 
} 
button{
  border-radius: 20%;
  border-color: white;
  background-color: solid rgba(0, 0.0, 0.0, 0.0);

}
button:hover{
  /* background-color: black; */
}
.bottom-line {
  bottom: 180px;
  position: absolute;
  border-bottom: 2px solid white; 
  width: 78%;
  z-index: 1;
  left: 100px;


}
.interior-line {
  bottom: 25%;
  position: absolute;
  border-bottom: 2px solid white; 
  width: 40%;
  z-index: 1;
  left: 32%;


}
.portfolio-container{
  width: 80%;
  padding-top: 12%;
  /* background-color: rgba(0.1, 0.4, 0.2, 0.4); */
}
.portfolio-header{
 
  background-color: black;


}
#tit_display1{
  display: none;
}

@media (max-width: 768px) {
  .bottom-line {
    display: none;
  
  
  }
  .interior-line{
    display: none;}
  .mobileView{
    /* margin-top: 30%; */
  }
  .selectedImage{
    margin-top: 5%;
  width: 400px;
  height: 100px;
  padding-bottom: 2%;
  padding-left: 8%;
  }
  .thumbnail-gallery img {
  display: none;
  }
  .main-image-container img{
    display: none;
  }
  .swiper-container {
      display: none; /* Hide slider on mobile */
  }
  .textStyle{
    font-size:40px;
  }
  .hoverText{
    display: none;
  }
  .portfolio-container{
    width: 80%;
    padding-top: 20%;
    /* background-color: rgba(0.1, 0.4, 0.2, 0.4); */
  }
  #title-des{
    display: none;
  }
  #title-des:hover{
    color: white !important;
  }
  #tit_display{
    display: none;
  }
  #tit_display1{
    /* padding-left: 6%; */
    font-size: 50px;
    display: inline;
    z-index: 1;
  }
}

.scroll-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 10px 15px;
  background-color: rgba(0.1, 0.4, 0.2, 0.4);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  z-index: 1000;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
}

.scroll-button:hover {
  background-color: rgba(0.1, 0.4, 0.2, 0.9);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  padding-top: 5%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 10px;
  border-radius: 8px;
  max-height: 80vh;
  width: 80%;
  position: relative;
  overflow-y: auto; /* Enables vertical scrolling */
}

/* Modal Body */
.modal-body {
  max-height: 60vh;
  overflow-y: auto; /* Ensures the content inside is scrollable */
}

/* Close Button */
.modal-close {
  width:50px;
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  background-color: gray;
  z-index: 0;
  color:white;
}

.project-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top:10% ;
  width: 80%;
  margin-left: 5%;
  margin-bottom: 2%;

}

.main-image-container {

  /* width: 800px;
  max-width: 1200px;
  height: 500px; */
}
.main-image-container  img{

  width: 1300px;
 max-width: 1300px;
  height: 500px;
}

.thumbnail-gallery {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.thumbnail-gallery img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.thumbnail-gallery img.selected {
  border: 2px solid #ffc107; /* Yellow border for selected image */
}

/* Button Style */
.profileButton{
  position: relative;
  bottom: 2%; 
  left: 50%; 
  transform: translateX(-50%); 
  /* background-color: #3cc7f3; Button background color */
  color: white; 
  border:solid;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  /* border-color: #3cc7f3; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 15%;
  background-color: rgba(0.1, 0.4, 0.2, 0.4);
}

.profileButton:hover {
  background-color: black; 
}
@media (min-width: 768px) {
.selectedImage{
  display: none;
}

}
/* Container for each slide content */
.slide-content-client {
  position: relative;
  overflow: hidden;
  border: solid lightcyan;

  /* margin-bottom: 20px; */
 
  
  
  
}


/* Image styling */
.slide-content-client img {
  width: 30px;
  height: 30px;
  object-fit:cover;
  /* transition: height 0.3s ease, filter 0.3s ease; */
  border-radius: 50%;

  
  
  /* filter: brightness(0.9); */
}

#custom-tawk-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 300px; /* Adjust dimensions as necessary */
  height: auto;
}


























