/* Footer Styles */
.footer {
  background: linear-gradient(135deg, #2e2e2e, #1a1a1a);
  color: #fff;
  padding: 60px 20px;
  text-align: center;
  z-index: -1;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
 
}

.footer-content {
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 30px;
}

/* Card-like style for Services and Contact Form */
.card {
  background-color: #333;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 32%;
  min-width: 300px;
}

/* Our Services */
.our-services h4 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.our-services ul {
  list-style: none;
  padding: 0;
}

.our-services li {
  margin: 10px 0;
  font-size: 1.1rem;
}

/* Contact Form */
.contact-form h4 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}
.contact-list{
  font-size: 20px;
  
  text-align: left;
  padding-left: 10px;
}
.contact-info p {
  display: flex;
  align-items: center; /* Align icons with text */
  margin: 5px 0; /* Space between contact info items */
}

.contact-info p svg {
  margin-right: 10px; /* Space between icon and text */
  color: #ffffff; /* Color for the icons */
  font-size: 18px; /* Size for the icons */
}

.form-group {
  margin-bottom: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: none;
  background-color: white;
  color: black;
  font-size: 1rem;
}

.form-group textarea {
  resize: none;
  height: 100px;
}

button[type="submit"] {
  padding: 12px 25px;
  background-color: #040505;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: white;
  color:black
}


/* Social Media - Horizontal Layout */
.social-media {
  margin-top: 40px;
  display: flex;
  justify-content: center; /* Centers the icons horizontally */
  align-items: center;
}

.icons {
  display: flex;
  gap: 15px; /* Adds space between the icons */
}

.icons a {
  color: #fff;
  font-size: 28px;
  transition: color 0.3s ease;
}

.icons a:hover {
  color: #007bff;
}

/* Footer Bottom */
.footer-bottom {
  margin-top: 40px;
}

.footer-bottom p {
  font-size: 0.9rem;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .card {
    width: 100%;
  }
}
.service-list{
  font-size: 20px;
  /* font-weight: bold; */
  text-align: left;
  padding-left: 90px;
}
.contact-info {
  text-align: center; /* Center align contact info */
}
.footer-title{
  font-size: 46px;
}

